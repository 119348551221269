<template>
    <div class="mode_light">
    <div class="breadcrumbs" :class="{ scrolling: scroll }">
    <div class="breadcrumbs__container">
      <ol class="breadcrumbs__breadcrumb">
        <li class="breadcrumbs__breadcrumb__item link__item">
          <router-link :to="'/'">Home</router-link>
        </li>
        <li
          v-if="this.title.path !== '/'"
          class="breadcrumbs__breadcrumb__item link__item"
        >
           <a href="#" @click.prevent="backBreadCrumb()">{{ title.name }}</a>
        </li>
        <li class="breadcrumbs__breadcrumb__item">
          {{ project.head_title? project.head_title:project.title }}
        </li>
      </ol>
    </div>
  </div>
      <div class="project-watch-head">
        <h1 class="project-watch-title container">{{ project.head_title? project.head_title:project.title }}</h1>
        <h4 v-if="project.head_title" class="project-watch-title_sub">{{project.title}}</h4>
      </div>
      <div class="project-watch-head__image-background">
      <div class="project-watch-head__image container">
        <img
          v-webp:src="
            require(`@/assets/portfolio/spa-portfolio/head_product_images/${project.head_image}.webp`)
          "
          :src="
            require(`@/assets/portfolio/spa-portfolio/head_product_images/${project.head_image}.png`)
          "
          :alt="project.head_image"
          loading="lazy"
        />
      </div>
      </div>
      <div class="project-watch-head__text" v-if="project.head_text_before_icon">
       <section-layout
          name=""
          title=""
          id="head__text"
        >
        <div v-html="project.head_text_before_icon"></div>
        <img
          v-if="project.head_text_icon"
          class="project-watch-head__text_icon"
          v-webp:src="
            require(`@/assets/portfolio/spa-portfolio/new_icons/${project.head_text_icon}.webp`)
          "
          :src="
            require(`@/assets/portfolio/spa-portfolio/new_icons/${project.head_text_icon}.jpg`)
          "
          :alt="project.head_text_icon"
          loading="lazy"
        />
        <div v-if="project.head_text_after_icon" v-html="project.head_text_after_icon"></div>
      </section-layout>
      </div>
      <div
        class="project-watch-products-group"
        v-for="product in project.group_products"
        :key="product.id"
      >
        <section-layout
          :name="product.title"
          :title="product.title"
          :id="product.title"
        >
          <div class="project-watch-products-group__desc" v-if="product.desc" v-html="product.desc"></div>
          <div class="project-watch-products-group__icons" v-if="product.icons">
            <img
            v-for="icon in product.icons" :key="icon"
              v-webp:src="
                require(`@/assets/portfolio/spa-portfolio/new_icons/${icon}.webp`)
              "
              :src="
                require(`@/assets/portfolio/spa-portfolio/new_icons/${icon}.jpg`)
              "
              :alt="icon"
              loading="lazy"
            />
          </div>
          <div class="project-watch-products-group__text"  v-html="product.text"></div>
            <div class="project-watch-products-group__icons-bottom" v-if="product.icons_bottom">
            <img
              v-for="icon in product.icons_bottom" :key="icon"
              v-webp:src="
                require(`@/assets/portfolio/spa-portfolio/new_icons/${icon}.webp`)
              "
              :src="
                require(`@/assets/portfolio/spa-portfolio/new_icons/${icon}.jpg`)
              "
              :alt="icon"
              loading="lazy"
            />
          </div>

          <div class="project-watch-products-group__head-image">
            <img
            v-for="image in product.product_image" :key="image"
            v-webp:src="
              require(`@/assets/portfolio/spa-portfolio/head_product_images/${image}.webp`)
            "
            :src="
              require(`@/assets/portfolio/spa-portfolio/head_product_images/${image}.png`)
            "
            :alt="image"
            loading="lazy"
          />


          </div>
        </section-layout>
      </div>
    </div>
</template>
<script>
import SectionLayout from "../../layouts/SectionLayout.vue";

export default {
  name:'ModeLightSPA',
  components:{
    SectionLayout
  },
  props:['project','title','scroll'],
  methods:{
      setBackgroundSection(){
        let section=document.querySelector('.project-watch-head__image-background')
        section.style.setProperty('--bgColor',this.project.colorBackground)

      },
      backBreadCrumb(){
        this.$router.push(this.title.path);
    },
  },
  mounted(){
    this.setBackgroundSection();
    document.title = this.project.head_title ? this.project.head_title : this.project.title
  }
}
</script>
<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.scrolling {
  border-bottom: 1px solid $red;
  .breadcrumbs__breadcrumb {
    margin: 10px 0;
  }
}
.project-watch-head__image-background{
    &:root{
      --bgColor:none;
    }
    margin:0 auto;
    @include media('>widescreen-xl'){
    width:100vw;
    margin:0;
    background-color:var(--bgColor)
    }
  }
</style>
